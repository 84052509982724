import { Component } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Component({
  selector: 'app-icon-app-energy',
  styleUrls: ['./icons.scss'],
  standalone: true,
  template: `<svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M60.7,493.9l160.5-221.6L85.3,213.3L352.4,16.9L253,177.4l192,30L60.7,493.9z M139.4,207.2l123.5,53.7 l-76.3,105.4l190.6-142l-168.7-26.4l47.2-76.2L139.4,207.2z" />
      <rect
        x="211.5"
        y="366.9"
        transform="matrix(0.8104 -0.5858 0.5858 0.8104 -167.0217 231.2581)"
        width="124.7"
        height="13.6" />
      <polygon points="204.9,431.8 197.1,420.7 212.5,409.9 220.3,421 	" />
    </g>
  </svg>`,
})
export class IconAppEnergyComponent extends AbstractIconComponent {}
