import { Component } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Component({
  selector: 'app-icon-exit',
  styleUrls: ['./icons.scss'],
  standalone: true,
  template: ` <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 14.7273V18H8.5V14.7273H13.8125V3.27273H8.5V0H17V14.7273ZM3.56734 14.1818L2.10269 12.2771L4.59956 10.2175H0V7.78255H4.59903L2.10269 5.72345L3.56734 3.81818L7.96291 7.44382L9.84991 9L7.96291 10.5567L3.56734 14.1818Z" />
  </svg>`,
})
export class IconExitComponent extends AbstractIconComponent {}
