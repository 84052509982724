import { Component } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Component({
  selector: 'app-icon-app-facility',
  styleUrls: ['./icons.scss'],
  standalone: true,
  template: `<svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M359.1,72.6c-35.2,0-64.6,24.9-71.9,57.9h-73.8c-7.2-33.1-36.7-57.9-71.9-57.9c-40.6,0-73.7,33.1-73.7,73.7 c0,40.6,33,73.7,73.7,73.7c36.4,0,66.5-26.6,72.4-61.4h72.7c5.2,30.4,28.9,54.5,59.2,60v0.9c0,13.7-11.1,24.9-24.8,24.9H168.4 c-0.6,0-1.3,0.1-1.9,0.1c-28.9,0.4-52.2,23.9-52.2,52.9v36.1c0,29.2,23.7,52.9,52.9,52.9h7.3c7.4,32.8,36.8,57.4,71.8,57.4 s64.4-24.6,71.8-57.4h23.1c7.8,0,14-6.3,14-14c0-7.8-6.3-14-14-14H319c-5.7-35-36.1-61.9-72.7-61.9s-67.1,26.9-72.7,61.9h-6.4 c-13.7,0-24.8-11.2-24.8-24.9v-36.1c0-13.7,11.1-24.9,24.8-24.9c0.6,0,1.2-0.1,1.8-0.1h152c29.2,0,52.9-23.7,52.9-52.9v-1.1 c33.6-6.9,58.9-36.6,58.9-72.2C432.7,105.7,399.7,72.6,359.1,72.6z M200.7,370.2c0-0.6,0-1.1,0-1.7c0.2-3.5,0.8-6.8,1.7-10.1h-0.2 c5.2-19.5,23-33.8,44.1-33.8s38.9,14.4,44.1,33.8h-0.2c0.9,3.3,1.5,6.6,1.7,10.1c0,0.6,0,1.1,0,1.7c0,5.7-1.2,11.2-3.1,16.3h0.1 c-6.6,17.1-23.2,29.3-42.6,29.3s-36-12.2-42.6-29.3h0.1C201.9,381.4,200.7,376,200.7,370.2z M373.9,189.2 c-4.7,1.6-9.6,2.7-14.8,2.7c-4.6,0-9-0.9-13.3-2.2c-14.8-4.5-26.2-16.2-30.5-31.1c-1.1-3.9-1.9-8-1.9-12.3c0-5.6,1.1-10.8,3-15.7 c6.4-17.4,23-29.9,42.6-29.9c25.1,0,45.6,20.5,45.6,45.6C404.7,166.2,391.7,183.1,373.9,189.2z M95.9,146.3 c0-25.1,20.5-45.6,45.6-45.6c19.6,0,36.2,12.5,42.6,29.9c1.8,4.9,3,10.2,3,15.7c0,4.3-0.8,8.4-1.9,12.3 c-5.4,19.1-22.9,33.3-43.7,33.3C116.4,191.9,95.9,171.4,95.9,146.3z" />
      <path
        d="M167.2,145.1h7c0-16.8-13.6-30.4-30.4-30.4v7c0,0,0,0,0,0l0,0v7c9,0,16.4,7.4,16.4,16.4H167.2L167.2,145.1 C167.2,145.1,167.2,145.1,167.2,145.1z" />
      <path
        d="M359.8,114.6v7c0,0,0,0,0,0l0,0v7c9,0,16.4,7.4,16.4,16.4h7l0,0c0,0,0,0,0,0h7 C390.2,128.3,376.6,114.6,359.8,114.6z" />
      <path d="M265.8,367.9h7l0,0c0,0,0,0,0,0h7c0-16.8-13.7-30.4-30.5-30.4v14C258.4,351.4,265.8,358.8,265.8,367.9z" />
    </g>
  </svg>`,
})
export class IconAppFacilityComponent extends AbstractIconComponent {}
